"use client";

import { GlobalStyles } from "@mui/material";

export function LayoutStyles() {
	return (
		<GlobalStyles
			styles={(theme) => ({
				body: {
					backgroundColor: theme.palette.background.default,
				},
			})}
		/>
	);
}
