"use client";

import { ReactNode, createContext, useContext } from "react";
import { User } from "./types";

export type AuthContextValue = {
	user: User | null;
};

export const AuthContext = createContext<AuthContextValue>({
	user: null,
});

export function AuthProvider({
	user,
	children,
}: {
	user: User | null;
	children: ReactNode;
}) {
	return (
		<AuthContext.Provider
			value={{
				user,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export const useAuth = () => useContext(AuthContext);
